export enum InvoiceRequestsState {
    EDITED = 'EDITED',
    RECEIPT = 'RECEIPT',
    PAID = 'PAID'
}

export interface BusinessUnit {
    id: number
    name: string
    notifyMainContact: boolean
    organizationId: number
    organizationName: string
}

export interface InvoiceRequestJSON {
    reference: string
    business_unit: BusinessUnit
    date_paid: string | null
    date_created: string | null
    date_exported: string | null
    date_receipt: string | null
    total_untaxed_amount: string | null
    external_invoice_no: string | null
    filename: string | null
}

export interface InvoiceRequest {
    reference: string
    businessUnit: BusinessUnit
    datePaid: string | null
    dateCreated: string | null
    dateExported: string | null
    dateReceipt: string | null
    totalUntaxedAmount: string | null
    externalInvoiceNo: string | null
    filename: string | null
}
