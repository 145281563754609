import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import useSessionStore from './session'
import {
    GMAddressComponent,
    GMAddressDetail,
    GMPrediction,
    TypeZone,
    Zone,
    ZoneJSON
} from '~/interfaces/zone'
import { axiosListWrapper } from '~/utilitary/storeWrapper'
import { keysToSnake, keysToCamel } from '~/plugins/json/utils'

const useZoneStore = defineStore('zone', () => {
    const createUpdateZone = (
        context: any,
        payload: { zone: Zone; idBU: number; idZone: number | undefined }
    ) => {
        const { zone, idBU, idZone } = payload
        const newZoneFormatted: ZoneJSON = keysToSnake(zone) as ZoneJSON
        let method = 'post'
        let url = `/supervisionapi/business-units/${idBU}/zones`
        if (idZone) {
            method = 'patch'
            url += `/${idZone}`
        }
        return context.$axios[method](url, newZoneFormatted)
    }

    const fetchZones = (
        context: any,
        payload: {
            search: string
            idBU: number
            type?: TypeZone | null
            idDeliveryPoint?: number | string
        }
    ) => {
        const { search, idDeliveryPoint, idBU, type } = payload

        const params = {
            keyword: search || undefined,
            type,
            delivery_point_id: idDeliveryPoint
        }

        const url = `/supervisionapi/business-units/${idBU}/zones`
        return context.$axios
            .$get(url, { params })
            .then((res: Zone) => keysToCamel(res))
    }

    const fetchZone = (
        context: any,
        payload: { idBU: string; idZone: string }
    ) => {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.$get(url).then((res: Zone) => keysToCamel(res))
    }

    const fetchDetailedZones = (
        context: any,
        payload: {
            idBU: number
            index?: number
            zoneName?: string
            zoneType: TypeZone
            isConnected?: boolean
            rebillingActivated?: boolean
            keyword?: string
            overstayParkingTariffActivated?: boolean
            hasOverstayParkingLicense?: boolean
            overstayParkingTariffRenewable?: boolean
        }
    ) => {
        const {
            index,
            idBU,
            zoneName,
            zoneType,
            rebillingActivated,
            isConnected,
            overstayParkingTariffActivated,
            hasOverstayParkingLicense,
            overstayParkingTariffRenewable,
            keyword
        } = payload

        const params = {
            type: zoneType,
            zone_name: zoneName,
            rebilling_activated: rebillingActivated,
            is_connected: isConnected,
            overstay_parking_tariff_activated: overstayParkingTariffActivated,
            has_overstay_parking_license: hasOverstayParkingLicense,
            overstay_parking_tariff_renewable: overstayParkingTariffRenewable
        }

        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(context)(url, params, {
                pages: { perPage: 20, index },
                searchField: keyword
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    }

    const getCollaboratorZones = (
        context: any,
        payload: {
            idBU: string
            index: number
            perPage: number
            collaboratorId?: number
        }
    ) => {
        const { idBU, collaboratorId, index, perPage } = payload
        const params = {
            type: TypeZone.DOMESTIC,
            collaborator_id: collaboratorId
        }
        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(context)(url, params, {
                pages: { perPage, index },
                searchField: ''
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    }

    const assignDeliveryPointToZone = (
        context: any,
        payload: {
            idDeliveryPoint: number | null
            idBU: number
            idZone: number
        }
    ) => {
        const { idBU, idZone, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.patch(url, {
            delivery_point_id: idDeliveryPoint
        })
    }

    const deleteZone = (
        context: any,
        payload: {
            idBU: string
            idZone: number
        }
    ) => {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return context.$axios.delete(url)
    }

    const fetchAddressList = (context: any, location: string) => {
        return context.$axios
            .$get('/supervisionapi/places/maps/api/place/autocomplete/json', {
                params: {
                    input: location,
                    types: 'geocode',
                    sessiontoken: useSessionStore().GMSession
                }
            })
            .then((response: { predictions: GMPrediction[] }) => {
                if (response?.predictions?.length === 0) return []
                return response.predictions.map((prediction: GMPrediction) => ({
                    address: prediction.description || '',
                    label: prediction.description || '',
                    lat: 0,
                    lng: 0,
                    city: '',
                    postalCode: '',
                    countryCode: '',
                    placeId: prediction.place_id || ''
                }))
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const fetchAddressDetails = (context: any, place_id: string) => {
        return context.$axios
            .$get('/supervisionapi/places/maps/api/place/details/json', {
                params: {
                    place_id,
                    fields: 'geometry,address_components,formatted_address',
                    sessiontoken: useSessionStore().GMSession
                }
            })
            .then((response: { result: GMAddressDetail }) => {
                const streetNumber =
                    response.result.address_components.find(
                        (component: GMAddressComponent) =>
                            component.types.includes('street_number')
                    )?.long_name || ''

                const address =
                    response.result.address_components.find(
                        (component: GMAddressComponent) =>
                            component.types.includes('route')
                    )?.long_name || ''

                const city =
                    response.result.address_components.find(
                        (component: GMAddressComponent) =>
                            component.types.includes('locality')
                    )?.long_name || ''

                const postalCode =
                    response.result.address_components.find(
                        (component: GMAddressComponent) =>
                            component.types.includes('postal_code')
                    )?.long_name || ''

                const countryCode =
                    response.result.address_components.find(
                        (component: GMAddressComponent) =>
                            component.types.includes('country')
                    )?.short_name || ''

                const formattedAddress = address
                    ? `${streetNumber} ${address}`
                    : response.result.formatted_address || ''

                return {
                    address: formattedAddress,
                    label:
                        `${streetNumber} ${address} ${postalCode} ${city}` ||
                        '',
                    lat: response.result.geometry.location.lat,
                    lng: response.result.geometry.location.lng,
                    city,
                    postalCode,
                    countryCode
                }
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    return {
        createUpdateZone,
        fetchZones,
        fetchZone,
        fetchDetailedZones,
        getCollaboratorZones,
        assignDeliveryPointToZone,
        deleteZone,
        fetchAddressList,
        fetchAddressDetails
    }
})

type ZoneStore = Omit<
    ReturnType<typeof useZoneStore>,
    keyof ReturnType<typeof defineStore>
>

export default useZoneStore

export type { ZoneStore }
