
import Vue from 'vue'
import TemporaryDrawerTitleBar from './TemporaryDrawerTitleBar.vue'

const defaultDrawerHeight = 400

export default Vue.extend({
    name: 'TemporaryChargemapDrawer',
    components: { TemporaryDrawerTitleBar },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        canClose: {
            type: Boolean,
            default: true
        },
        closeTrigger: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left',
            validator: (value: string) => ['left', 'right'].includes(value)
        },
        forcedMobile: {
            type: Boolean,
            default: false
        },
        noScroll: {
            type: Boolean,
            default: false
        },
        closeHeight: {
            type: Number,
            default: 75
        },
        drawerWidth: {
            type: Number,
            default: 530
        },
        forceFullSize: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            drawerVisible: this.visible,
            drawerHeight: 0,
            isResizing: false,
            initialY: 0,
            initialHeight: 0,
            isFullSize: this.forceFullSize,
            drawCloseButton: this.showCloseButton,
            windowWidth: 769,
            animateSize: false,
            fakeDivHeight: 0,
            fakeDivWidth: 0,
            gotOpened: false,
            resizeObserver: null as null | ResizeObserver,
            timeout: null as null | number | NodeJS.Timeout,
            interval: null as null | number | NodeJS.Timeout
        }
    },
    computed: {
        isDesktopDrawer() {
            return this.windowWidth > 768 && !this.forcedMobile
        },
        drawerPositionStyle(): object {
            if (this.position === 'left') {
                return {
                    left:
                        this.drawerVisible || !this.isDesktopDrawer
                            ? 0
                            : `-${this.drawerWidth}px`
                }
            }
            return {
                right:
                    this.drawerVisible || !this.isDesktopDrawer
                        ? 0
                        : `-${this.drawerWidth}px`
            }
        }
    },
    watch: {
        closeTrigger(val) {
            if (val) {
                if (this.canClose) {
                    this.close()
                } else {
                    this.softCloseDrawer()
                }
            }
        },
        visible: {
            handler(val) {
                this.drawerVisible = val
                if (val) {
                    this.drawerHeight = defaultDrawerHeight
                } else {
                    this.drawerHeight = 0
                }
                this.gotOpened = true
                this.timeout = setTimeout(() => {
                    this.gotOpened = false
                }, 100)
            },
            immediate: true
        },
        isFullSize: {
            handler() {
                this.updateShouldDrawButton()
            },
            immediate: true
        },
        isDesktopDrawer: {
            handler() {
                this.updateShouldDrawButton()
            },
            immediate: true
        },
        drawCloseButton(val) {
            this.$emit('showCloseButton', val)
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.animateSize = false
        this.timeout = setTimeout(() => {
            this.windowWidth = window.outerWidth
            this.animateSize = true
        }, 100)
        this.resizeObserver = new ResizeObserver(this.onResizeDrawer)
        this.resizeObserver.observe(this.$refs.drawer as Element)
        this.fakeDivWidth =
            this.drawerWidth + (this.$refs.drawer as HTMLElement).offsetLeft
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        this.resizeObserver?.unobserve(this.$refs.drawer as Element)
    },
    methods: {
        updateShouldDrawButton(this: any) {
            this.drawCloseButton =
                (!this.isDesktopDrawer && this.isFullSize) ||
                (this.isDesktopDrawer && this.showCloseButton)
        },
        onResize(this: any) {
            this.windowWidth = window.outerWidth
            this.animateSize = this.drawerVisible
            clearTimeout(this.timeout as number)
            this.timeout = setTimeout(() => {
                this.animateSize = true
            }, 100)
        },
        onResizeDrawer(this: any) {
            // We need to set the height of the fake div to the height of the drawer -10 so it takes the rounded corners into account
            this.fakeDivHeight = Math.max(
                (this.$refs.drawer as Element).clientHeight - 10,
                0
            )
        },
        close() {
            this.$emit('updateVisible', false)
        },
        startResize(this: any, e: MouseEvent | TouchEvent) {
            e.preventDefault()
            this.isResizing = true
            this.initialY =
                (e as MouseEvent).clientY ||
                (e as TouchEvent).touches[0].clientY
            this.initialHeight = this.drawerHeight
            // Disable body scroll
            document.body.style.overflow = 'hidden'
            document.addEventListener('mousemove', this.handleResize)
            document.addEventListener('touchmove', this.handleResize, {
                passive: false
            })
            document.addEventListener('mouseup', this.stopResize)
            document.addEventListener('touchend', this.stopResize)
        },
        handleResize(this: any, e: MouseEvent | TouchEvent) {
            e.preventDefault()
            if (!this.isResizing) return
            const currentY =
                (e as MouseEvent).clientY ||
                (e as TouchEvent).touches[0].clientY
            const deltaY = currentY - this.initialY
            const newHeight = this.initialHeight - deltaY
            // Get the window height
            const windowHeight = window.innerHeight - 20
            // Restrict the maximum height to the window height
            const maxDrawerHeight = Math.min(windowHeight, newHeight)
            this.drawerHeight = maxDrawerHeight
            // Check if the drawer is too small and close it
            if (newHeight < this.closeHeight) {
                if (this.canClose) {
                    this.closeDrawer()
                    this.drawerHeight = 0
                } else {
                    this.drawerHeight = this.closeHeight
                }
            }
            if (newHeight > windowHeight - 75) {
                this.isFullSize = true
                this.stopResize()
            }
        },
        closeDrawer(this: any) {
            if (this.isFullSize && !this.forceFullSize) {
                this.softCloseDrawer()
            } else {
                this.close()
            }
            this.stopResize()
        },
        // This allows to remove the full screen mode when the button is clicked
        softCloseDrawer(this: any) {
            this.isFullSize = false
            this.drawerHeight = defaultDrawerHeight
        },
        stopResize(this: any) {
            this.isResizing = false
            document.body.style.overflow = 'auto' // Re-enable body scroll
            document.removeEventListener('mousemove', this.handleResize)
            document.removeEventListener('touchmove', this.handleResize)
            document.removeEventListener('mouseup', this.stopResize)
            document.removeEventListener('touchend', this.stopResize)
        },
        animationStart(this: any) {
            if (this.isResizing || !this.gotOpened) return
            if (this.interval) {
                clearInterval(this.interval as number)
            }
            // we check the left position of the drawer every 1ms
            this.interval = setInterval(() => {
                if (this.position === 'left') {
                    this.fakeDivWidth =
                        this.drawerWidth +
                            (this.$refs.drawer as HTMLElement)?.offsetLeft || 0
                } else if (this.position === 'right') {
                    this.fakeDivWidth =
                        window.outerWidth -
                            (this.$refs.drawer as HTMLElement)?.offsetLeft ||
                        0 + (window.outerWidth - window.innerWidth)
                }
            }, 1)
            setTimeout(() => {
                this.animationEnd()
            }, 700)
        },
        animationEnd(this: any) {
            clearInterval(this.interval as number)
        }
    }
})
