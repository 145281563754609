import { DeliveryPoint, DeliveryPointDTO } from '~/interfaces/deliveryPoint'
import { Chargebox, ChargeboxJSON, evseStatuses } from '~/interfaces/chargebox'

export enum TypeZone {
    PRIVATE = 'PRIVATE',
    DOMESTIC = 'DOMESTIC',
    CONNECTED_CABLE = 'CONNECTED_CABLE'
}

export enum RebillingAndOverstayParkingTariffFilter {
    NONE = 'NONE',
    WITHOUT_REBILLING = 'WITHOUT_REBILLING',
    WITH_REBILLING = 'WITH_REBILLING',
    WITH_REBILLING_AND_OVERSTAY_PARKING_TARIFF = 'WITH_REBILLING_AND_OVERSTAY_PARKING_TARIFF'
}

export interface ZoneChargebox {
    id: number
    isConnected: boolean | null
    lastCall: string | null
}
export interface ZoneCommon {
    id: number
    type?: TypeZone
    zoneType?: TypeZone
    name: string
}

export interface GPSCoordinates {
    latitude: number | null
    longitude: number | null
}

export interface Zone extends ZoneCommon {
    businessUnitId?: number
    address: string
    postalCode: string
    city: string
    countryCode: string
    chargeboxes?: Chargebox[]
    chargeboxesCount?: number
    completeAddress?: string
    deliveryPoint?: DeliveryPoint | null
    deliveryPointId?: number | null
    notifyCollaborator: boolean
    gpsCoordinates?: GPSCoordinates
}

export interface ZoneJSON {
    id: number
    type: TypeZone
    business_unit_id: number
    name?: string
    address: string
    postal_code: string
    city: string
    country_code: string
    chargeboxes: ChargeboxJSON[]
    delivery_point?: DeliveryPointDTO | null
    gps_coordinates?: GPSCoordinates
}

export interface ZoneEventEvseStatuses {
    id: number
    status: evseStatuses
}

export interface ZoneEventStatus {
    chargebox: ZoneChargebox
    evse: ZoneEventEvseStatuses
    connector: ZoneEventEvseStatuses
}

export interface GMPrediction {
    description: string
    matched_substrings: {
        length: number
        offset: number
    }[]
    place_id: string
    reference: string
    structured_formatting: {
        main_text: string
        main_text_matched_substrings: {
            length: number
            offset: number
        }[]
        secondary_text: string
    }
    terms: {
        offset: number
        value: string
    }[]
    types: Array<string>
}

interface Coordinates {
    lat: number
    lng: number
}

export interface GMAddressComponent {
    long_name: string
    short_name: string
    types: string[]
}

export interface GMAddressDetail {
    address_components: GMAddressComponent[]
    formatted_address: string
    geometry: {
        location: Coordinates
        viewport: {
            northeast: Coordinates
            southwest: Coordinates
        }
    }
}
