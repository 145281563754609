import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
    InvoiceRequest,
    InvoiceRequestsState
} from '~/interfaces/invoiceRequest'
import { InvoiceRequestFilter } from '~/interfaces/filters'

const useInvoiceRequestStore = defineStore('invoiceRequests', () => {
    const invoiceRequests = ref<InvoiceRequest[]>([])
    const invoiceRequestsCount = ref<number | null>(null)

    const fetchInvoiceRequests = (
        context: any,
        payload: {
            idOrga: number | string
            idBU: number | string
            filters: InvoiceRequestFilter
        }
    ) => {
        const { filters } = payload
        const { pages, minStartDate, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            start_date: minStartDate || null,
            handled: state ? state === InvoiceRequestsState.PAID : null,
            receipt: state ? state !== InvoiceRequestsState.EDITED : null,
            keyword: keyword || null,
            order_by: 'date_exported,desc'
        }
        let url = '/supervisionapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }

        url += '/invoice-requests'

        return context.$axios
            .get(url, { params })
            .then((res: any) => {
                const result = context.$toCamel(res.data)
                invoiceRequests.value = result.items
                invoiceRequestsCount.value = Number(
                    res.headers['x-total-count']
                )
                return result
            })
            .catch((e: any) => {
                throw e
            })
    }

    const generateCSV = (
        context: any,
        payload: {
            idOrga: number | string
            idBU: number | string
            filename: number | string
        }
    ) => {
        const { idOrga, idBU, filename } = payload
        let url = '/supervisionapi'
        if (idBU) {
            url += `/business-units/${idBU}`
        } else if (idOrga) {
            url += `/organizations/${idOrga}`
        }

        url += '/invoice-requests/export'

        return context.$axios
            .get(url, {
                params: {
                    filename
                }
            })
            .catch((e: any) => {
                throw e
            })
    }

    return {
        fetchInvoiceRequests,
        generateCSV,
        invoiceRequests,
        invoiceRequestsCount
    }
})

type InvoiceRequestsStore = Omit<
    ReturnType<typeof useInvoiceRequestStore>,
    keyof ReturnType<typeof defineStore>
>

export default useInvoiceRequestStore

export type { InvoiceRequestsStore }
