
import Vue from 'vue'
export default Vue.extend({
    name: 'ChargemapCardRadioButton',
    props: {
        isSelected: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            refHeight: 60,
            totalPaddingAndMargin: 34
        }
    },
    watch: {
        // Set new height when isSelected changes
        isSelected() {
            const refCardRadioButtonElement = this.$refs
                ?.chargemapCardRadioButton as HTMLElement
            const refLabelElement = this.$refs
                ?.chargemapCardRadioButtonLabel as HTMLElement

            if (!refCardRadioButtonElement) return

            // Same as setTimeout but more optimized for animations
            requestAnimationFrame(() => {
                this.refHeight = this.isSelected
                    ? refCardRadioButtonElement.scrollHeight +
                      refCardRadioButtonElement.clientHeight
                    : refLabelElement.scrollHeight + this.totalPaddingAndMargin
            })

            // This is for a smooth animation
            if (this.isSelected) {
                setTimeout(() => {
                    refCardRadioButtonElement.style.overflow = 'visible'
                }, 300)
            }
        }
    },
    // Set default height on mounted
    mounted() {
        const refLabelElement = this.$refs
            ?.chargemapCardRadioButtonLabel as HTMLElement

        if (this.isSelected) {
            this.refHeight = 700
        } else {
            requestAnimationFrame(() => {
                this.refHeight =
                    refLabelElement.scrollHeight + this.totalPaddingAndMargin
            })
        }
    }
})
